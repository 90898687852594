<template>
  <div class="relative">
    <div v-loading="isLoading || isRendering">
      <div class="cs-sign flex justify-between items-center py-2 px-4 gap-4 mb-4">
        <div class="fs-20 font-bold uppercase text-white">{{ getEnvelopeNameByType(documentType) }}</div>

        <div class="flex gap-2">
          <el-button
            class="fs-16"
            size="small"
            plain
            :disabled="!canSign"
            type="primary"
            @click="handleSignDocument"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
              />
            </svg>
            {{ $t("Ký") }}
          </el-button>
          <!-- <SelectSignature
            :signerID="signerID"
            @onChange="handleChangeImageSignature"
          />-->
          <el-button
            class="fs-16"
            size="small"
            plain
            type="primary"
            @click="handleClear"
          >{{ $t("Đóng") }}</el-button>
        </div>
      </div>
      <div class="text-black mt-14">
        <div class="p-2 cursor-none cs-container">
          <div :id="containerID" class="pdf-container cs-cursor">
            <img
              @click="handleClickSignature"
              v-if="signatureImage && !hasSignedEnvelope && isValidSignOrder"
              class="cs-image-pdf"
              :id="imageID"
              :src="signatureImage"
              alt="Moving Image"
              :width="imageX"
              :height="imageY"
            />
            <div
              v-else-if="!hasSignedEnvelope && isValidSignOrder"
              :id="`${imageID}-default`"
              @click="handleClickSignature"
              class="cs-default-signature"
            >
              <div class="font-bold">{{ $t("Ký") }}</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                <path
                  fill="currentColor"
                  d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                />
              </svg>
            </div>
            <vue-pdf-embed
              :ref="elementID"
              :id="elementID"
              :source="urlPDF"
              @internal-link-clicked="handleClickedPage"
              @loaded="handleDocumentLoad"
              @rendered="handleDocumentRender"
            />
          </div>
        </div>
      </div>
      <div v-if="showPopup && signatureFocus" :style="popupStyle" class="popup flex flex-col gap-2">
        <div class="font-bold">{{ signatureFocus.signatory_name }}</div>
        <div>{{ signatureFocus.signatory_email }}</div>
        <div :set="(imageSrc = handleGetImageBase64(signatureFocus))">
          <img v-if="imageSrc" style="width: 80px; height: 40px" :src="imageSrc" />
        </div>
        <div :set="(signAt = getSignAt(signatureFocus))">
          <div v-if="signAt">
            <strong class="mr-1">Đã ký vào:</strong>
            <i>{{ signAt }}</i>
          </div>
        </div>
      </div>
    </div>
    <ModalSelectSignature ref="ModalSelectSignature" @onChange="handleChangeImageSignature" />
  </div>
</template>
    
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import SignatureRequest from '@/api/request/SignatureRequest'
import { SIGNATORY_STATUS } from '../../utils/constants'
import SelectSignature from '../../components/Signature/SelectSignature.vue'
import ModalSelectSignature from '../../components/Signature/ModalSelectSignature.vue'
import uploadS3File from '../../utils/uploadS3File'
import appUtils from '../../utils/appUtils'
import { mixinSignPdf } from '../../utils/mixinSignPdf'

export default {
  name: 'SignEnvelope',
  components: { VuePdfEmbed, SelectSignature, ModalSelectSignature },
  mixins: [mixinSignPdf],
  data () {
    return {
      isLoading: false,
      pageCount: null,
      signatureImage: '',
      isRendering: true,
      imageX: 80,
      imageY: 40,
      urlPDF: '',
      documentPDFViewer: null,
      metadata: null,
      documentType: null,
      signerID: null,
      documentID: null,
      selectedSignature: null,
      containerID: 'SignEnvelopeContainerPDF',
      elementID: 'SendEnvelopeSignerPDF',
      imageID: 'SendEnvelopeImageID',
      envelopeDetail: {},
      currentSignatory: null,
      signatureFocus: null,
      showPopup: false,
      popupStyle: {}
    }
  },
  computed: {
    request () {
      return new SignatureRequest()
    },
    canSign () {
      return (
        this.signerID === this.$user?.id &&
        this.selectedSignature?.signature_name &&
        !this.hasSignedEnvelope &&
        this.isValidSignOrder
      )
    },
    hasSignedEnvelope () {
      return this.currentSignatory?.status === SIGNATORY_STATUS.signed
    },
    isValidSignOrder () {
      return this.envelopeDetail?.envelope_signatories
        ?.filter(
          item => item?.signatory_order < this.currentSignatory?.signatory_order
        )
        .every(item => item?.status === SIGNATORY_STATUS.signed)
    }
  },
  watch: {},
  created () {
    this.handleGetSignEnvelope()
  },
  mounted () {
    // this.handleCheckMouseInPage()
  },
  methods: {
    handleClear () {
      const self = this
      const container = document.getElementById(this.containerID)

      container.removeEventListener('mousemove', e => {})

      const canvases = container?.querySelectorAll('canvas')
      canvases.forEach((item, index) => {
        item.removeEventListener('mousedown', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1)
        })
      })

      this.urlPDF = ''
      this.documentPDFViewer = null
      this.metadata = null
      this.selectedSignature = null
      this.signerID = null
      this.documentID = null
      this.documentType = null

      this.$router.replace({
        name: 'HomePage'
      })
    },
    handleCheckMouseInPage () {
      const container = document.getElementById(this.containerID)
      const canvases = container?.querySelectorAll('canvas')
      const self = this
      canvases.forEach((item, index) => {
        item.addEventListener('mousedown', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, true)
          self.canvasSelectedClicked = !self.canvasSelectedClicked
        })
      })

      canvases.forEach((item, index) => {
        item.addEventListener('mousemove', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, false)
        })
      })
    },
    async handleCalcPositionSignature (canvas, event, page, isClick) {
      if (isClick && this.showPopup) {
        this.showPopup = false
        return
      }

      const pageViewer = await this.getPDFPageViewer(page)

      // Lấy kích thước của canvas
      const rect = canvas.getBoundingClientRect()

      // Tính toán tọa độ x, y của chuột trên canvas
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // Tính toán tọa độ trên PDF từ tọa độ chuột trên canvas
      const viewport = pageViewer.getViewport({ scale: 1.0 })
      const pdfX = (x / rect.width) * viewport.width
      const pdfY = (y / rect.height) * viewport.height
      const pointY = parseInt(viewport.height - pdfY)
      const pointX = pdfX

      this.envelopeDetail.envelope_signatories.forEach(signatory => {
        const [
          signLLx,
          signLLy,
          signURx,
          signURy
        ] = signatory?.signatory_metadata?.signature_position_rectangle_pdf
          ?.split(',')
          ?.map(item => parseInt(item))

        // Check if point focus in signLLx, signLLy, signURx, signURy
        if (
          pointX >= signLLx &&
          pointX <= signURx &&
          pointY >= signLLy &&
          pointY <= signURy &&
          page == signatory?.signatory_metadata?.signature_position_page
        ) {
          if (isClick) {
            this.showPopup = true
            const canvasHeight = rect.height
            const pageHeight = (page - 1) * canvasHeight
            this.popupStyle = {
              top: `${y + pageHeight - 200}px`,
              left: `${x}px`
            }
            this.signatureFocus = signatory
          } else {
            canvas.style.cursor = 'pointer' // Add this line to set the cursor to pointer
          }
        } else {
          // this.showPopup = false
          canvas.style.cursor = 'default' // Add this line to set the cursor to pointer
        }
      })
    },
    handleDocumentLoad (documentPDF) {
      this.pageCount = documentPDF.numPages
      this.documentPDFViewer = documentPDF
    },
    handleDocumentRender (data) {
      console.log(data)
      if (!this.isRendering) return
      // this.handleCheckMouseInPage()
      this.handleSetSignaturePosition()
      this.handleCheckMouseInPage()
      this.isRendering = false
    },
    handleClickedPage (page) {
      console.log('clicked link', page)
    },

    async handleSignDocument () {
      if (!this.$route.params?.id) return

      try {
        const isValid = this.handleValidateSignDocument()
        if (!isValid) return

        this.isLoading = true

        const base64Document = await this.getFileAsBase64(this.urlPDF)

        if (!base64Document) return

        const params = {
          workername: this.selectedSignature?.signature_name,
          envelope_id: Number(this.$route.params.id),
          signatory_id: this.currentSignatory.id,
          document_base64: base64Document
          // signatory_id:
        }

        const response = await this.request.signDocumentWithEnvelope(params)

        if (response.status == 200) {
          const [envelopeUrl] =
            (await this.handleUploadFilesAWS(response.data)) || []

          await this.handleUpdateEnvelopeDocumentURL(envelopeUrl)

          this.$message({
            type: 'success',
            message: this.$t('Ký tài liệu thành công')
          })

          this.handleClear()
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: this.$t('Ký tài liệu thất bại')
        })
      } finally {
        this.isLoading = false
      }
    },
    async getPDFPageViewer (pageNum) {
      const pageViewer = await this.documentPDFViewer.getPage(pageNum)
      return pageViewer
    },
    handleChangeImageSignature (data) {
      if (!data?.signature_base64) return

      const base64 = data.signature_base64
      this.signatureImage = this.generateImageSrcBase64(base64)

      this.selectedSignature = data
      this.$nextTick(() => {
        this.handleSetSignaturePosition()
      })
    },
    generateImageSrcBase64 (content) {
      var prefix = 'data:image/png;base64,'

      return prefix + content
    },
    handleValidateSignDocument () {
      if (!this.urlPDF) return

      if (!this.selectedSignature?.signature_name || !this.signatureImage) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm thấy thông tin chữ ký')
        })
        return
      }

      if (!this.canSign) {
        this.$message({
          type: 'error',
          message: this.$t('Bạn không có quyền ký tài liệu')
        })
      }

      if (!this.currentSignatory.id) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm được thông tin người ký')
        })
        return
      }

      return true
    },

    async handleGetSignEnvelope () {
      try {
        const id = this.$route.params.id
        if (!id) return

        const response = await this.request.getSignEnvelopeByID(id)
        this.envelopeDetail = response.data || {}
        this.handleProcessEnvelopeData(this.envelopeDetail)
      } catch (error) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm thấy thông tin tài liệu')
        })
        console.log(error)
      }
    },
    async handleProcessEnvelopeData (envelopeInfo) {
      const signatory = envelopeInfo?.envelope_signatories?.find(
        item => item?.user_id === this.$user?.id
      )
      if (!signatory) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền truy cập và ký tài liệu này'
        })

        return
      }
      this.documentType = envelopeInfo?.document_type
      this.documentID = envelopeInfo?.document_id
      // this.urlPDF =
      //   'data:application/pdf;base64,' + envelopeInfo?.document_base64

      const s3Url = await uploadS3File.getLinkVideoAWS(
        envelopeInfo?.document_url
      )

      this.urlPDF = s3Url
      this.currentSignatory = signatory

      this.signerID = signatory?.user_id
    },
    handleSetSignaturePosition () {
      if (!this.currentSignatory) return

      if (this.hasSignedEnvelope) {
        this.$toast.open({
          type: 'warning',
          message: 'Bạn đã ký tài liệu này'
        })
        return
      }

      if (!this.isValidSignOrder) {
        this.$toast.open({
          type: 'warning',
          message: 'Bạn phải ký theo thứ tự ! Vui lòng chờ đến lượt ký của bạn'
        })
        return
      }

      const [
        signLLx,
        signLLy
      ] = this.currentSignatory?.signatory_metadata?.signature_position_rectangle
        ?.split(',')
        ?.map(item => parseInt(item))

      const signX = signLLx + this.imageX / 2
      const signY = signLLy + this.imageY / 2

      const imageDefault = document.getElementById(`${this.imageID}-default`)
      const image = document.getElementById(`${this.imageID}`)

      if (imageDefault) {
        imageDefault.style.left = signX + 'px'
        imageDefault.style.top = signY + 'px'

        this.$nextTick(() => {
          imageDefault.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
        })
      }

      if (image) {
        image.style.left = signX + 'px'
        image.style.top = signY + 'px'

        this.$nextTick(() => {
          image.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
        })
      }
    },
    handleClickSignature () {
      this.$refs.ModalSelectSignature.handleOpen(
        this.selectedSignature,
        this.signerID
      )
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    },
    handleGetImageBase64 (data) {
      if (!data?.signatory_metadata?.signature?.signature_base64) return ''

      const prefix = 'data:image/png;base64,'
      const content = data?.signatory_metadata?.signature?.signature_base64
      return prefix + content
    },
    getSignAt (data) {
      return this.signed_at || data.signatory_metadata?.sign_at
        ? window
          .moment(data.signatory_metadata?.sign_at)
          .format(' HH:mm:ss DD/MM/YYYY')
        : ''
    },
    async getFileAsBase64 (url) {
      try {
        // Fetch the file from the URL
        const response = await fetch(url)

        // Ensure the response is ok
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        // Get the Blob from the response
        const blob = await response.blob()

        // Convert the Blob to a Base64 string
        const base64String = await this.blobToBase64(blob)
        const base64 = this.removePrefixDocument(base64String)

        return base64
      } catch (error) {
        console.error('Error fetching or converting the file:', error)
      }
    },

    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    removePrefixDocument (base64) {
      let prefix = 'data:application/pdf;base64,'
      if (base64.startsWith(prefix)) {
        return base64.slice(prefix.length)
      }
      return base64
    },
    async handleUploadFilesAWS (base64) {
      if (!this.documentID || !base64) return []

      const formatBase64 = 'data:application/pdf;base64,' + base64

      const file = appUtils.convertBase64tToFile(
        formatBase64,
        this.getEnvelopeNameByType(this.documentType)
      )

      const prefixPath = `resources/sign_envelopes/${this.documentType}/`

      const resultUrl = await uploadS3File.uploadHandler(
        this.documentID,
        window.moment().valueOf(),
        [file],
        1,
        prefixPath
      )

      return resultUrl?.map(item => item?.url)
    },
    async handleUpdateEnvelopeDocumentURL (url) {
      if (!this.envelopeDetail?.id) return

      try {
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(this.envelopeDetail.id, {
          document_url: url
        })

        console.log({ response })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
    
<style lang="scss" scoped>
.cs-sign {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(74, 74, 74);
  z-index: 1000;
  width: 100%;
}

.vue-pdf-embed {
  margin: 0 auto;
}

.vue-pdf-embed__page {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.cs-container {
  overflow: auto;
  // pointer-events: none;
}

.pdf-container {
  width: 1000px;
  border: 1px solid #000;
  margin: 0 auto;
  position: relative;
}

::v-deep {
  .vue-pdf-embed {
    .vue-pdf-embed__page {
      box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .cs-dialog {
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
  }
}

.cs-image-pdf {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
  cursor: pointer;
}

.cs-default-signature {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc820;
  cursor: pointer;
}

.popup {
  position: absolute;
  background-color: white;
  z-index: 20000;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
</style>