<template>
  <div>
    <el-dialog
      title="Chọn Chữ Ký"
      :visible.sync="isOpen"
      :width="isTablet || isMobile ? '100%' : '70%'"
      :before-close="handleClose"
      top="100px"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Chọn Chữ Ký") }}
        </div>
      </template>
      <div class="text-black">
        <el-tabs v-model="currentTab" @tab-click="handleClick">
          <el-tab-pane label="exist" name="exist">
            <span slot="label" class="fs-16">{{ $t("Danh sách Chữ ký") }}</span>
            <div
              v-loading="isLoading"
              class="mt-4 cs-signature-container"
              v-if="signatures && signatures.length"
            >
              <div
                v-for="(signature, index) in signatures"
                :key="index"
                class="cs-image-container cursor-pointer"
                :class="checkActive(signature) ? 'cs-active' : ''"
                @click.stop="(e) => handleSelectSignature(e, signature)"
              >
                <img
                  class="cs-image"
                  v-if="signature.signature_base64"
                  :src="generateImageSrcBase64(signature.signature_base64)"
                />
              </div>
            </div>
            <div v-else class="mt-4 flex items-center justify-center flex-col">
              <div v-loading="isLoading" class="text-center fs-16">
                {{ $t("Bạn chưa có chữ ký") }}
              </div>
              <el-button
                class="mx-auto rounded-3xl mt-2"
                @click="handleChangeTab('create')"
                plain
                type="primary"
              >
                <i class="el-icon-plus"></i>

                {{ $t("Tạo chữ ký") }}
              </el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="create" name="create">
            <span slot="label" class="fs-16">{{ $t("Tạo mới Chữ ký") }}</span>
            <div
              v-loading="isLoading"
              class="flex flex-col justify-center cs-container-create"
            >
              <div id="signature-pad">
                <canvas
                  id="signature-canvas"
                  width="1000"
                  height="600"
                ></canvas>
              </div>
              <br />
            </div>
          </el-tab-pane>
        </el-tabs>

        <div
          class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
        >
          <el-button
            :disabled="isLoading"
            plain
            type="primary"
            @click="handleClose"
            >{{ $t("Đóng") }}</el-button
          >
          <el-button
            v-if="currentTab === 'create'"
            :disabled="isLoading"
            plain
            type="primary"
            @click="clearSignature()"
            >{{ $t("Xoá chữ ký") }}</el-button
          >
          <el-button
            :disabled="isLoading"
            type="primary"
            @click="handleSubmit"
            >{{ $t("Xác nhận") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { isMobile, isTablet } from 'mobile-device-detect'
import SignatureRequest from '../../api/request/SignatureRequest'

export default {
  name: 'ModalSelectSignature',
  data () {
    return {
      isOpen: false,
      currentTab: 'exist',
      isLoading: false,
      signatures: [],
      selectedSignature: null,
      signerID: null,
      isMobile,
      isTablet
    }
  },
  watch: {
    currentTab () {
      if (this.currentTab === 'create') {
        this.$nextTick(() => {
          this.getDrawingSignature()
        })
      } else {
        this.clearSignature()
        this.getDoctorSignatures()
      }
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
    },
    async handleOpen (signature, signerIDProp) {
      this.isOpen = true
      this.signerID = signerIDProp
      await this.getDoctorSignatures()

      if (signature) {
        this.selectedSignature = signature
      } else {
        this.selectedSignature = this.signatures?.[0] || {}
      }
    },
    async handleSubmit () {
      if (this.currentTab === 'create') {
        this.handleCreateSignature()
      } else {
        this.$emit('onChange', this.selectedSignature)
        this.isOpen = false
      }
    },
    handleClick (tab, event) {
      //   this.currentTab = tab
      //   console.log(tab, event)
    },
    generateImageSrcBase64 (content) {
      var prefix = 'data:image/png;base64,'

      return prefix + content
    },
    handleSelectSignature (e, item) {
      e.stopPropagation()

      this.selectedSignature = item
      //   this.isOpen = false
      //   this.$emit('onChange', item)
    },
    checkActive (item) {
      return item.id === this.selectedSignature?.id
    },
    async getDoctorSignatures () {
      if (!this.signerID) return

      try {
        this.isLoading = true
        const request = new SignatureRequest()

        const params = {
          user_id: this.signerID
        }
        const response = await request.getDoctorSignatures(params)
        this.signatures = response.data || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleChangeTab (tab) {
      this.currentTab = tab
    },
    getDrawingSignature () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')
      let isDrawing = false
      let lastX = 0
      let lastY = 0

      canvas.addEventListener('mousedown', startDrawing)
      canvas.addEventListener('mousemove', draw)
      canvas.addEventListener('mouseup', endDrawing)
      canvas.addEventListener('mouseout', endDrawing)

      canvas.addEventListener('touchstart', startDrawing)
      canvas.addEventListener('touchmove', draw)
      canvas.addEventListener('touchend', endDrawing)

      function startDrawing (e) {
        isDrawing = true
        let rect = canvas.getBoundingClientRect()
        let x = e.clientX - rect.left
        let y = e.clientY - rect.top;
        [lastX, lastY] = [x, y]
      }

      function draw (e) {
        if (!isDrawing) return
        let x, y
        if (e.type.startsWith('touch')) {
          x = e.touches[0].pageX - canvas.offsetLeft
          y = e.touches[0].pageY - canvas.offsetTop
        } else {
          let rect = canvas.getBoundingClientRect()
          x = e.clientX - rect.left
          y = e.clientY - rect.top
        }

        ctx.beginPath()
        ctx.moveTo(lastX, lastY)
        ctx.lineTo(x, y)
        ctx.strokeStyle = '#0000FF'
        ctx.lineWidth = 3
        ctx.lineCap = 'round'
        ctx.stroke();
        [lastX, lastY] = [x, y]
      }

      function endDrawing () {
        isDrawing = false
      }
    },
    clearSignature () {
      const canvas = document.getElementById('signature-canvas')
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },

    saveSignature () {
      let croppedData = this.getCroppedImage()
      this.handleSaveDrawImage(croppedData)
    },
    getCroppedImage () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      let data = imageData.data

      let minX = canvas.width
      let minY = canvas.height
      let maxX = -1
      let maxY = -1
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          let index = (y * canvas.width + x) * 4
          if (data[index + 3] > 0) {
            if (x < minX) minX = x
            if (x > maxX) maxX = x
            if (y < minY) minY = y
            if (y > maxY) maxY = y
          }
        }
      }

      let croppedWidth = maxX - minX + 1
      let croppedHeight = maxY - minY + 1
      let croppedCanvas = document.createElement('canvas')
      let croppedCtx = croppedCanvas.getContext('2d')
      croppedCanvas.width = croppedWidth
      croppedCanvas.height = croppedHeight
      croppedCtx.putImageData(imageData, -minX, -minY)

      return croppedCanvas.toDataURL()
    },
    async handleCreateSignature () {
      try {
        this.isLoading = true
        const imageBase64 = this.getCroppedImage()

        const cleanedBase64String = this.removePrefix(imageBase64)
        const workerName =
          'worker' + window.moment().unix() + 'U' + (this.signerID || '')

        // TODO: Need update Default key, crypto token
        const params = {
          properties: {
            // description: 'Nguyen Minh Quang',
            name: workerName,
            // LOCATION: "HODO-Ha Noi, Vietnam",
            DEFAULTKEY: process.env.VUE_APP_SIGNATURE_DEFAULT_KEY,
            CRYPTOTOKEN: process.env.VUE_APP_SIGNATURE_CRYPTO_TOKEN,
            TYPE: 'PROCESSABLE',
            ADD_VISIBLE_SIGNATURE: 'True',
            DIGESTALGORITHM: 'SHA256',
            IMPLEMENTATION_CLASS: 'org.signserver.module.pdfsigner.PDFSigner',
            AUTHTYPE: 'NOAUTH',
            DISABLEKEYUSAGECOUNTER: 'false',
            ALLOW_PROPERTY_OVERRIDE:
              'VISIBLE_SIGNATURE_RECTANGLE,VISIBLE_SIGNATURE_PAGE,LOCATION,REASON,description,CERTIFICATION_LEVEL',
            VISIBLE_SIGNATURE_CUSTOM_IMAGE_BASE64: cleanedBase64String,
            // REASON: "Quang Stamped",
            user_id: this.signerID
          }
        }

        const request = new SignatureRequest()

        const response = await request.createSignature(params)

        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('Tạo chữ kí thành công')
          })
          this.$emit('onChange', response.data?.data)
          this.handleClose()
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: this.$t('Tạo chữ kí thất bại')
        })
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    toBinary (string) {
      const codeUnits = new Uint16Array(string.length)
      for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i)
      }
      return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)))
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    }
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 10px;
  }
}

.cs-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cs-image-container {
  cursor: pointer;
  padding: 8px;
  border: 1px dashed #000;
  width: 200px;
  height: 100px;
  margin: 0 auto;
}

.cs-active {
  border: 2px dashed #0fda20;
}

.cs-signature-container {
  height: 400px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 20px;

  @media (max-width: 1410px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.cs-btn-create {
  padding: 10px;
  border: 1px dashed #2a88e7;
  border-radius: 20px;
  max-width: 150px;
  margin: 16px auto 0;
  text-align: center;
}

.cs-create-signature {
  border: 2px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

#signature-pad {
  border: 1px dashed #000;
  width: fit-content;
}

.cs-container-create {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
}
</style>